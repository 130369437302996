import { BullaBankerCreatedEvent, BullaTagUpdatedEvent } from '../domain/bulla-banker-domain';
import { ClaimCreatedEvent, ClaimPaymentEvent, ClaimRejectedEvent, ClaimRescindedEvent, FeePaidEvent } from '../domain/bulla-claim-domain';
import { FinancingAcceptedEvent } from '../domain/bulla-finance-domain';
import { BullaInstantPaymentContractEvent, InstantPaymentEvent, InstantPaymentTagUpdatedEvent } from '../domain/bulla-instant-pay-domain';
import { EnabledModuleEvent, DisabledModuleEvent, BullaBankerModuleDeployEvent, ERC721TransferEvent } from '../domain/common-domain';
import {
    InvoiceFundedEvent,
    InvoiceImpairedEvent,
    InvoiceKickbackAmountSentEvent,
    InvoiceReconciledEvent,
    InvoiceUnfactoredEvent,
} from '../domain/factoring-domain';
import { LoanOfferAcceptedEvent } from '../domain/frendlend-domain';
import { OrderCreatedEvent } from '../domain/swap-domain';
import { FrendLendEvent, MappedEventType, PoolEvent, BullaSwapEvent } from './mapped-event-types';

export const getModuleConfigEvents = (enabledEvents: MappedEventType[] | undefined) =>
    enabledEvents?.filter(
        (ev): ev is EnabledModuleEvent | DisabledModuleEvent =>
            ev.__typename === 'EnabledModuleEvent' || ev.__typename === 'DisabledModuleEvent',
    ) ?? [];

export const getInstantPaymentEvents = (event: MappedEventType[] | undefined) =>
    event?.filter((ev): ev is InstantPaymentEvent => ev.__typename === 'InstantPaymentEvent') ?? [];
export const getInstantPaymentTagUpdatedEvents = (event: MappedEventType[] | undefined) =>
    event?.filter((ev): ev is InstantPaymentTagUpdatedEvent => ev.__typename === 'InstantPaymentTagUpdatedEvent') ?? [];
export const isInstantPaymentEvent = (ev: MappedEventType): ev is BullaInstantPaymentContractEvent =>
    ev.__typename === 'InstantPaymentEvent' || ev.__typename === 'InstantPaymentTagUpdatedEvent';

export const getBullaBankerModuleDeployEvents = (createdEvents: MappedEventType[] | undefined) =>
    createdEvents?.filter((ev): ev is BullaBankerModuleDeployEvent => ev.__typename === 'BullaBankerModuleDeployEvent') ?? [];
export const getBullaBankerCreatedEvents = (createdEvents: MappedEventType[] | undefined) =>
    createdEvents?.filter((ev): ev is BullaBankerCreatedEvent => ev.__typename === 'BullaBankerCreatedEvent') ?? [];
export const getBullaTagUpdatedEvents = (bullaTags: MappedEventType[] | undefined) =>
    bullaTags?.filter((tags): tags is BullaTagUpdatedEvent => tags.__typename === 'BullaTagUpdatedEvent') ?? [];

export const getClaimCreatedEvents = (claims: MappedEventType[] | undefined) =>
    claims?.filter((claim): claim is ClaimCreatedEvent => claim.__typename === 'ClaimCreatedEvent') ?? [];
export const getSwapCreatedEvents = (claims: MappedEventType[] | undefined) =>
    claims?.filter((claim): claim is OrderCreatedEvent => claim.__typename === 'OrderCreatedEvent') ?? [];
export const getERC721TransferEvents = (claims: MappedEventType[] | undefined) =>
    claims?.filter((ev): ev is ERC721TransferEvent => ev.__typename === 'ERC721TransferEvent') ?? [];
export const getClaimPaymentEvents = (event: MappedEventType[] | undefined) =>
    event?.filter((ev): ev is ClaimPaymentEvent => ev.__typename === 'ClaimPaymentEvent') ?? [];
export const getClaimRejectedEvents = (event: MappedEventType[] | undefined) =>
    event?.filter((ev): ev is ClaimRejectedEvent => ev.__typename === 'ClaimRejectedEvent') ?? [];
export const getClaimRescindedEvents = (event: MappedEventType[] | undefined) =>
    event?.filter((ev): ev is ClaimRescindedEvent => ev.__typename === 'ClaimRescindedEvent') ?? [];
export const getFeePaidEvents = (event: MappedEventType[] | undefined) =>
    event?.filter((ev): ev is FeePaidEvent => ev.__typename === 'FeePaidEvent') ?? [];
export const getLoanOfferAcceptedEvents = (event: MappedEventType[] | undefined) =>
    event?.filter((ev): ev is LoanOfferAcceptedEvent => ev.__typename === 'LoanOfferAcceptedEvent') ?? [];

export const getFinancingAcceptedEvents = (event: MappedEventType[] | undefined) =>
    event?.filter((ev): ev is FinancingAcceptedEvent => ev.__typename === 'FinancingAcceptedEvent') ?? [];

export const isFrendLendEvent = (ev: MappedEventType): ev is FrendLendEvent =>
    ev.__typename === 'LoanOfferedEvent' || ev.__typename === 'LoanOfferRejectedEvent' || ev.__typename === 'LoanOfferAcceptedEvent';

export const isFactoringEvent = (ev: MappedEventType): ev is InvoiceFundedEvent | InvoiceKickbackAmountSentEvent =>
    ev.__typename === 'InvoiceFundedEvent' || ev.__typename === 'InvoiceKickbackAmountSentEvent';

export const isUnFactoredEvent = (ev: MappedEventType): ev is InvoiceUnfactoredEvent => ev.__typename === 'InvoiceUnfactoredEvent';

export const isImpairedEvent = (ev: MappedEventType): ev is InvoiceImpairedEvent => ev.__typename === 'InvoiceImpairedEvent';

export const isInvoiceReconciledEvent = (ev: MappedEventType): ev is InvoiceReconciledEvent => ev.__typename === 'InvoiceReconciledEvent';

export const isPoolEvent = (event: MappedEventType): event is PoolEvent =>
    event.__typename === 'DepositMadeEvent' || event.__typename === 'SharesRedeemedEvent';

export const isBullaSwapEvent = (event: MappedEventType): event is BullaSwapEvent =>
    event.__typename === 'OrderCreatedEvent' || event.__typename === 'OrderExecutedEvent' || event.__typename === 'OrderDeletedEvent';
