import React from 'react';
import { PageLayoutProvider } from '../layout/page-layout';
import { CreateItemLinkHandler } from './create-claim-modal/create-link-handler';
import { Box } from '@chakra-ui/react';

export const LinkPayPage = () => {
    return (
        <PageLayoutProvider>
            <Box width="100%" height="100%">
                <CreateItemLinkHandler />
            </Box>
        </PageLayoutProvider>
    );
};
