import { IN_DEV_ENV, IN_STAGING_ENV } from './env-constants';

const useAlphaFeatures = IN_DEV_ENV || IN_STAGING_ENV;
export const vendorFinancingEnabled = useAlphaFeatures;
export const overridePremiumStatus = false; // no longer premium for all
export const enableHumaFactoring = useAlphaFeatures;
export const enableFactoringDashboard = true;
export const enableReporting = true;
export const enableWalletUnderwritingScore = true;
export const truckerFinanceEnabled = false;
export const includeTestnetsInDashboard = useAlphaFeatures;
export const enableMandatoryPremium = true;
export const testingUpgradePath = false; // needs to be set to false at all times except for testing
export const enableBullaFactoringPool = true;
export const enableBftPricingOnTestnet = useAlphaFeatures;
export const enableTcsPricingOnPolygon = true;
export const enableEditingWalletsInMembership = true;
export const enableBullaPro = true;
export const hideRecentReportsTable = true;
export const enable1099wizard = true;
export const enable8949wizard = true;
export const bypassProForReportingExplorer = IN_DEV_ENV;
export const goStraightToLastPageIn1099 = false;
export const goStraightToLastPageIn8949 = false;
export const enableBatchFee = false;
export const enableSwaps = true;
export const enableNewInvoiceCreation = useAlphaFeatures;
export const enableNewLinksPage = useAlphaFeatures;
