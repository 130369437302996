import { BigNumber } from '@ethersproject/bignumber';
import { constants } from 'ethers';
import { TxHashBitmap } from '../tools/common';

import {
    BullaItemInfo,
    BullaSwapInfo,
    ClaimInfo,
    FinancingOrigination,
    FinancingState,
    FrendLendInfo,
    InstantPaymentInfo,
    PoolDepositInfo,
    PoolRedemptionInfo,
    TAG_SEPARATOR,
    VendorFinancingOrigination,
} from './data-model';
import { BullaTagUpdatedEvent } from './domain/bulla-banker-domain';
import { ClaimCreatedEvent } from './domain/bulla-claim-domain';
import { FinancingAcceptedEvent, FinancingOfferedEvent } from './domain/bulla-finance-domain';
import { ERC721TransferEvent, HumaFactorEvent } from './domain/common-domain';
import { LoanOfferAcceptedEvent, LoanOfferedEvent } from './domain/frendlend-domain';
import { daysToTermLength, FinancingTerms, getFinancingTerms } from './dto/bulla-finance-dto';
import {
    getClaimCreatedEvents,
    isFrendLendEvent,
    isInstantPaymentEvent,
    isFactoringEvent,
    isUnFactoredEvent,
    isPoolEvent,
    isInvoiceReconciledEvent,
    isBullaSwapEvent,
} from './dto/event-filters';
import { getUniqueEventId } from './dto/events-dto';
import {
    BullaSwapEvent,
    FactoringEvent,
    FrendLendEvent,
    InstantPayContractEvent,
    MappedClaimEvent,
    MappedEventType,
    PoolEvent,
} from './dto/mapped-event-types';
import { addressEquality, EthAddress } from './ethereum';
import { isClaim, secondsToDays, sortBlocknumAsc, ZERO_BIGNUMBER } from './helpers';
import { ChainId, NETWORKS } from './networks';
import { TokenInfoResolver } from '../hooks/useTokenRepo';
import { UserData } from '../tools/userData';
import {
    InvoiceFundedEvent,
    InvoiceImpairedEvent,
    InvoiceKickbackAmountSentEvent,
    InvoiceReconciledEvent,
    InvoiceUnfactoredEvent,
} from './domain/factoring-domain';

export type ClaimDictionary<T> = {
    [tokenId: string]: T;
};
export type ClaimEvent = ClaimCreatedEvent | MappedClaimEvent;
export type BullaItemEvent = ClaimEvent | InstantPayContractEvent | FrendLendEvent | FactoringEvent | BullaSwapEvent;

type MapToClaimInfoParams = {
    chainId: ChainId;
    resolveTokenInfo: TokenInfoResolver;
    claimEvent: ClaimCreatedEvent;
    userAddress: EthAddress;
    claimTransfers?: ERC721TransferEvent[];
    bullaTag: string | undefined;
    logs: ClaimEvent[];
    owners: EthAddress[];
    financingState: FinancingState;
};

export type ClaimLogData = {
    claimEvents: ClaimDictionary<ClaimEvent[]>;
    bullaTags: ClaimDictionary<string>;
    claimTransfers: ClaimDictionary<ERC721TransferEvent[]>;
    ipfsHashes: ClaimDictionary<string>;
    financingOffers: ClaimDictionary<FinancingTerms | undefined>;
    financingAccepted: ClaimDictionary<
        | {
              terms: FinancingTerms;
              origination: FinancingOrigination;
          }
        | undefined
    >;
    loanOffers: ClaimDictionary<
        | {
              terms: FinancingTerms;
          }
        | undefined
    >;
    humaMissingClaimId: string | null;
    currentCreditorByTokenId: Record<string, string>;
};

const bullaItemEventTypenames = [
    'TransferEvent',
    'ClaimCreatedEvent',
    'BullaTagUpdatedEvent',
    'ClaimPaymentEvent',
    'ClaimRescindedEvent',
    'ClaimRejectedEvent',
    'FeePaidEvent',
    'InstantPaymentEvent',
    'InstantPaymentTagUpdatedEvent',
    'FinancingAcceptedEvent',
    'FinancingOfferedEvent',
    'LoanOfferedEvent',
    'LoanOfferAcceptedEvent',
    'LoanOfferRejectedEvent',
    'ERC721TransferEvent',
    'InvoiceApprovedEvent',
    'InvoiceFundedEvent',
    'InvoiceKickbackAmountSentEvent',
    'InvoiceUnfactoredEvent',
    'InvoiceImpairedEvent',
    'OrderCreatedEvent',
    'OrderExecutedEvent',
    'OrderDeletedEvent',
];

export const itemTypeToString = (item: BullaItemInfo): string => (isClaim(item) ? item.claimType : 'Instant Payment');

export const getOnlyBullaItemRelatedLogs = (events: MappedEventType[]): BullaItemEvent[] =>
    events.filter((event): event is BullaItemEvent => bullaItemEventTypenames.includes(event.__typename));

export const getStatus = (claimEvents: ClaimEvent[], claimAmount: BigNumber) => {
    if (claimEvents.some(({ __typename }) => __typename === 'ClaimRejectedEvent')) return 'Rejected';
    if (claimEvents.some(({ __typename }) => __typename === 'ClaimRescindedEvent')) return 'Rescinded';

    const paidAmount = claimEvents.reduce(
        (paidAmount, action) => (action.__typename === 'ClaimPaymentEvent' ? paidAmount.add(action.paymentAmount) : paidAmount),
        ZERO_BIGNUMBER,
    );
    if (paidAmount.gte(claimAmount)) return 'Paid';
    else if (paidAmount.gt(ZERO_BIGNUMBER)) return 'Repaying';
    else if (
        claimEvents.some(({ __typename }) => __typename === 'InvoiceFundedEvent') &&
        claimEvents.some(({ __typename }) => __typename === 'InvoiceUnfactoredEvent')
    )
        return 'Unfactored';
    else if (claimEvents.some(({ __typename }) => __typename === 'InvoiceImpairedEvent')) return 'Impaired';
    else if (
        claimEvents.some(({ __typename }) => __typename === 'InvoiceFundedEvent') &&
        !claimEvents.some(({ __typename }) => __typename === 'InvoiceKickbackAmountSentEvent')
    )
        return 'Factored';
    return 'Pending';
};

const getInstantPaymentInfo = async (
    resolveTokenInfo: TokenInfoResolver,
    userAddress: EthAddress,
    chainId: ChainId,
    logs: InstantPayContractEvent[],
): Promise<InstantPaymentInfo[]> => {
    const paymentMap = await logs.reduce<Promise<{ [instantPaymentId: string]: InstantPaymentInfo }>>(async (_aggregator, log) => {
        const aggregator = await _aggregator;

        if (log.__typename === 'InstantPaymentEvent') {
            const id = getUniqueEventId({ txHash: log.txHash, logIndex: log.logIndex });
            const tokenInfo = await resolveTokenInfo(chainId, log.tokenAddress);
            return {
                ...aggregator,
                [id]: {
                    id,
                    __type: 'InstantPayment',
                    creditor: log.to,
                    debtor: log.from,
                    paidAmount: log.amount,
                    tokenInfo,
                    ipfsHash: log.ipfsHash,
                    description: log.description,
                    tags: addressEquality(log.from, userAddress) ? log.tag.split(TAG_SEPARATOR).filter(x => !!x) : [],
                    logs: [log],
                    created: log.blocktime,
                    txHash: log.txHash,
                    chainId,
                    lastPaymentDate: log.blocktime,
                    notes: '',
                },
            };
        } else {
            const ipId = log.txAndLogIndexHash;
            if (aggregator[ipId]) {
                return Promise.resolve({
                    ...aggregator,
                    [ipId]: {
                        ...aggregator[ipId],
                        tags: log.tag.split(TAG_SEPARATOR).filter(x => !!x),
                        logs: [...(aggregator[ipId]?.logs ?? []), log],
                    },
                });
            } else return aggregator;
        }
    }, Promise.resolve({}));
    return Object.values(paymentMap);
};

export const mapToClaimInfo = async ({
    claimEvent,
    chainId,
    resolveTokenInfo,
    claimTransfers,
    userAddress,
    bullaTag,
    logs,
    owners,
    financingState,
}: MapToClaimInfoParams): Promise<ClaimInfo> => {
    const [debtor, origin] = [claimEvent.debtor, claimEvent.origin];
    const [creditor, originalCreditor] =
        claimTransfers && claimTransfers.length > 0
            ? [claimTransfers[claimTransfers.length - 1].to, claimTransfers[0].from]
            : [claimEvent.creditor, claimEvent.creditor];

    const paidAmount = logs.reduce<BigNumber>((total, action) => {
        if (action.__typename === 'ClaimPaymentEvent') return total.add(action.paymentAmount);
        return total;
    }, ZERO_BIGNUMBER);

    const claimStatus = getStatus(logs, claimEvent.claimAmount);
    const token = await resolveTokenInfo(chainId, claimEvent.token);

    const claimType =
        addressEquality(userAddress, origin) || owners?.map(x => x.toLowerCase()).includes(origin.toLowerCase())
            ? addressEquality(userAddress, creditor) || addressEquality(userAddress, originalCreditor)
                ? 'Invoice'
                : 'Payment'
            : addressEquality(userAddress, debtor)
            ? 'Invoice'
            : 'Payment';

    const isFrendLendFinancingState = financingState.kind == 'accepted' && financingState.origination.kind === 'frendlend';
    const frendlendAcceptancePaymentIndex = logs.findIndex(
        log => isFrendLendFinancingState && log.__typename === 'ClaimPaymentEvent' && log.paymentAmount.eq(1),
    );
    const logsWithoutFrendLendAcceptancePayment =
        frendlendAcceptancePaymentIndex > -1 ? logs.filter((_, i) => i !== frendlendAcceptancePaymentIndex) : logs;

    return {
        id: claimEvent.tokenId,
        __type: 'Claim',
        claimType,
        origin: claimEvent.origin,
        creditor,
        debtor: claimEvent.debtor,
        claimAmount: isFrendLendFinancingState ? claimEvent.claimAmount.sub(1) : claimEvent.claimAmount,
        paidAmount: isFrendLendFinancingState ? paidAmount.sub(1) : paidAmount,
        tokenInfo: token,
        claimStatus,
        isTransferred: !!claimTransfers,
        dueBy: claimEvent.dueBy,
        description: claimEvent.description,
        tags: bullaTag?.split(TAG_SEPARATOR).filter(x => !!x) ?? [],
        ipfsHash: claimEvent.ipfsHash,
        logs: logsWithoutFrendLendAcceptancePayment,
        created: claimEvent.blocktime,
        txHash: claimEvent.txHash,
        chainId,
        lastPaymentDate: getLastPaymentDateFromLogs(logs),
        financingState,
        notes: '',
        originalCreditor,
    };
};

export async function getPoolEventInfo(
    poolEvents: PoolEvent[],
    resolveTokenInfo: TokenInfoResolver,
    chainId: ChainId,
): Promise<{ poolDeposits: PoolDepositInfo[]; poolRedemptions: PoolRedemptionInfo[] }> {
    const poolDeposits: PoolDepositInfo[] = [];
    const poolRedemptions: PoolRedemptionInfo[] = [];

    for (const event of poolEvents) {
        const factoringConfig = NETWORKS[chainId].factoringConfig?.find(x =>
            addressEquality(x.bullaFactoringToken.token.address, event.poolAddress),
        );
        const poolUnderlyingTokenInfo = factoringConfig!.poolUnderlyingToken;
        const poolUnderlyingTokenAddress = poolUnderlyingTokenInfo!.token.address;
        const underlyingTokenInfo = await resolveTokenInfo(chainId, poolUnderlyingTokenAddress!);
        const poolTokenInfo = await resolveTokenInfo(chainId, event.poolAddress);
        const poolName = factoringConfig!.poolName;

        if (event.__typename === 'DepositMadeEvent') {
            const depositInfo: PoolDepositInfo = {
                __type: 'PoolDeposit',
                paidAmount: event.assets,
                id: event.txHash,
                chainId,
                tokenInfo: underlyingTokenInfo,
                poolTokenInfo,
                poolName,
                txHash: event.txHash,
                created: event.blocktime,
                depositor: event.depositor,
                shares: event.sharesIssued,
                poolAddress: event.poolAddress,
                priceAfterTransaction: event.priceAfterTransaction,
                ipfsHash: event.ipfsHash,
                tags: [],
                description: '',
                creditor: event.poolAddress,
                debtor: event.depositor,
                notes: '',
            };
            poolDeposits.push(depositInfo);
        } else if (event.__typename === 'SharesRedeemedEvent') {
            const redemptionInfo: PoolRedemptionInfo = {
                __type: 'PoolRedemption',
                paidAmount: event.assets,
                id: event.txHash,
                tokenInfo: underlyingTokenInfo,
                poolTokenInfo,
                chainId,
                txHash: event.txHash,
                created: event.blocktime,
                redeemer: event.redeemer,
                shares: event.shares,
                poolAddress: event.poolAddress,
                poolName,
                priceAfterTransaction: event.priceAfterTransaction,
                ipfsHash: event.ipfsHash,
                tags: [],
                description: '',
                creditor: event.redeemer,
                debtor: event.poolAddress,
                notes: '',
            };
            poolRedemptions.push(redemptionInfo);
        }
    }

    return { poolDeposits, poolRedemptions };
}

export async function getSwapEventInfo(
    swapEvents: BullaSwapEvent[],
    resolveTokenInfo: TokenInfoResolver,
    chainId: ChainId,
): Promise<{ swapEventInfo: BullaSwapInfo[] }> {
    // Key to track and merge swap events
    const swapsById = new Map<string, BullaSwapInfo>();

    for (const event of swapEvents) {
        const signerTokenInfo = await resolveTokenInfo(chainId, event.order.signerToken);
        const senderTokenInfo = await resolveTokenInfo(chainId, event.order.senderToken);

        const orderId = event.order.orderId.toString();

        if (event.__typename == 'OrderCreatedEvent') {
            swapsById.set(orderId, {
                __type: 'Swap' as const,
                id: orderId,
                orderId: event.order.orderId,
                chainId,
                expiry: new Date(event.order.expiry.toNumber() * 1000),
                signerWallet: event.order.signerWallet,
                signerToken: signerTokenInfo,
                signerAmount: event.order.signerAmount,
                senderWallet: event.order.senderWallet,
                senderToken: senderTokenInfo,
                senderAmount: event.order.senderAmount,
                txHash: event.txHash,
                created: event.blocktime,
                description: 'Bulla Swap',
                tags: ['Bulla Swap'],
                status: 'Pending',
                paidAmount: event.order.signerAmount, // dummy value
                tokenInfo: signerTokenInfo, // dummy value
                creditor: event.signerWallet, // dummy value
                debtor: event.sender, // dummy value
                notes: '', // dummy value
            });
        } else if (event.__typename === 'OrderExecutedEvent') {
            swapsById.set(orderId, {
                ...swapsById.get(orderId)!,
                status: 'Executed',
                txHash: event.txHash,
                executed: event.blocktime,
            });
        } else if (event.__typename === 'OrderDeletedEvent') {
            swapsById.set(orderId, {
                ...swapsById.get(orderId)!,
                status: 'Canceled',
            });
        }
    }

    return { swapEventInfo: Array.from(swapsById.values()) };
}

const handleBullaTagUpdated = (logData: ClaimLogData, log: BullaTagUpdatedEvent) => {
    const { tokenId, tag } = log;
    // implicitly will update with the most recent tag if there are multiple and the events are sorted ASC
    const logDataWithBullaTag = {
        ...logData,
        bullaTags: {
            ...logData.bullaTags,
            [tokenId]: tag,
        },
    };
    return addToClaimEventsList(logDataWithBullaTag, log);
};

const handleClaimTransfers = (logData: ClaimLogData, log: ERC721TransferEvent) => {
    const logDataWithTransfers = {
        ...logData,
        claimTransfers: {
            ...logData.claimTransfers,
            [log.tokenId]: [...(logData.claimTransfers[log.tokenId] ?? []), log],
        },
    };
    return log.from === constants.AddressZero ? logData : addToClaimEventsList(logDataWithTransfers, log);
};

const handleClaimFactoredDrawdown = (logData: ClaimLogData, log: HumaFactorEvent) => {
    return addToClaimEventsList(logData, log);
};

const handleClaimFactoredExtra = (logData: ClaimLogData, log: HumaFactorEvent) => {
    if (logData.humaMissingClaimId && log.tokenId === '') {
        log.tokenId = logData.humaMissingClaimId;
    }
    logData.humaMissingClaimId = null;
    return addToClaimEventsList(logData, log);
};

const handleClaimUnfactored = (logData: ClaimLogData, log: HumaFactorEvent, queryAddress: string) => {
    if (logData.humaMissingClaimId && log.tokenId === '' && log.owner === queryAddress) {
        log.tokenId = logData.humaMissingClaimId;
    }
    logData.humaMissingClaimId = null;
    return addToClaimEventsList(logData, log);
};

const addToClaimEventsList = (logData: ClaimLogData, log: MappedClaimEvent | ClaimCreatedEvent) => ({
    ...logData,
    claimEvents: {
        ...logData.claimEvents,
        [log.tokenId]: [...(logData.claimEvents[log.tokenId] ?? []), log],
    },
});

/**
 * @param claimEvents only eventLogs related to claims
 * @returns a map of tokenIds: DATA. allows us to sort through all the logs at once and then have constant time access of relevant info.
 */
export const getClaimLogData = (claimEvents: ClaimEvent[], loanOfferedEvents: LoanOfferedEvent[], queryAddress: string): ClaimLogData => {
    const loanOffers = loanOfferedEvents.reduce<Record<string, LoanOfferedEvent>>((acc, event) => {
        acc[event.loanId] = event;
        return acc;
    }, {});

    const claimLogData = Object.values(claimEvents).reduce<ClaimLogData>(
        (logData, log) => {
            switch (log.__typename) {
                case 'ClaimPaymentEvent':
                    log.creditor = logData.currentCreditorByTokenId[log.tokenId];
                    return addToClaimEventsList(logData, log);
                case 'ClaimRejectedEvent':
                case 'ClaimRescindedEvent':
                case 'FeePaidEvent':
                    return addToClaimEventsList(logData, log);
                case 'ClaimCreatedEvent':
                    logData.currentCreditorByTokenId[log.tokenId] = log.creditor;
                    return addToClaimEventsList(logData, log);
                case 'BullaTagUpdatedEvent':
                    return handleBullaTagUpdated(logData, log);
                case 'ERC721TransferEvent':
                    logData.currentCreditorByTokenId[log.tokenId] = log.to;
                    return handleClaimTransfers(logData, log);
                case 'FinancingAcceptedEvent':
                    const newLogData = addToClaimEventsList(logData, log);
                    return handleFinancingAcceptedEvent(newLogData, log);
                case 'FinancingOfferedEvent':
                    const _newLogData = addToClaimEventsList(logData, log);
                    return handleFinancingOfferedEvent(_newLogData, log);
                case 'LoanOfferAcceptedEvent':
                    const _logData = addToClaimEventsList(logData, log);
                    return handleLoanOfferAcceptedEvent(_logData, log, loanOffers);
                case 'DrawdownOnReceivableEvent':
                    logData.humaMissingClaimId = log.tokenId;
                    return handleClaimFactoredDrawdown(logData, log);
                case 'ExtraFundsDispersedEvent':
                    return handleClaimFactoredExtra(logData, log);
                case 'PaymentMadeEvent':
                    return handleClaimUnfactored(logData, log, queryAddress);
                case 'InvoiceApprovedEvent':
                    return addToClaimEventsList(logData, log);
                case 'InvoiceFundedEvent':
                    return addToClaimEventsList(logData, log);
                case 'InvoiceKickbackAmountSentEvent':
                    return addToClaimEventsList(logData, log);
                case 'InvoiceUnfactoredEvent':
                    return addToClaimEventsList(logData, log);
                case 'InvoiceReconciledEvent':
                    return addToClaimEventsList(logData, log);
                case 'InvoiceImpairedEvent':
                    return addToClaimEventsList(logData, log);
            }
        },
        {
            claimEvents: {},
            bullaTags: {},
            claimTransfers: {},
            ipfsHashes: {},
            financingOffers: {},
            financingAccepted: {},
            loanOffers: {},
            humaMissingClaimId: null,
            currentCreditorByTokenId: {},
        },
    );

    return {
        ...claimLogData,
    };
};

export const getUserData = async (
    resolveTokenInfo: TokenInfoResolver,
    chainId: ChainId,
    queryAddress: EthAddress,
    bullaItemEvents: BullaItemEvent[],
    owners?: EthAddress[],
): Promise<Omit<UserData, 'refetch' | 'importedExternalTxs' | 'nonImportedExternalTxs' | 'refetchExternalTransactions'>> => {
    const allOwners = [queryAddress, ...(owners ?? [])];

    const eventsASC = Object.values(
        bullaItemEvents
            .sort(sortBlocknumAsc)
            .reduce<{ [uniqueKey: string]: BullaItemEvent }>((logs, log) => ({ ...logs, [log.txHash + log.logIndex]: log }), {}),
    );

    const { claimEvents, instantPaymentEvents, loanEvents, factoringEvents, poolEvents, swapEvents } = categorizeBullaItemEvents(eventsASC);

    const claimEventsWithRelatedFactoringEvents = [
        ...claimEvents,
        ...factoringEvents.filter(
            (
                x,
            ): x is
                | InvoiceFundedEvent
                | InvoiceKickbackAmountSentEvent
                | InvoiceUnfactoredEvent
                | InvoiceReconciledEvent
                | InvoiceImpairedEvent =>
                x.__typename === 'InvoiceFundedEvent' ||
                x.__typename === 'InvoiceKickbackAmountSentEvent' ||
                x.__typename === 'InvoiceUnfactoredEvent' ||
                x.__typename === 'InvoiceReconciledEvent' ||
                x.__typename === 'InvoiceImpairedEvent',
        ),
    ];

    const claimLogData: ClaimLogData = getClaimLogData(
        claimEventsWithRelatedFactoringEvents,
        loanEvents.filter((x): x is LoanOfferedEvent => x.__typename === 'LoanOfferedEvent'),
        queryAddress,
    );

    const getBullaClaimInfo = (claimEvent: ClaimCreatedEvent) => {
        const claimId = claimEvent.tokenId;

        const financingState: FinancingState = (() => {
            const acceptedFinancing = claimLogData.financingAccepted[claimId];
            if (acceptedFinancing !== undefined) {
                return { kind: 'accepted', ...acceptedFinancing };
            } else {
                const offeredFinancing = claimLogData.financingOffers[claimId];
                if (offeredFinancing !== undefined) {
                    return { kind: 'offered', terms: offeredFinancing };
                } else return { kind: 'no-financing' };
            }
        })();

        return mapToClaimInfo({
            chainId,
            resolveTokenInfo,
            claimEvent,
            userAddress: queryAddress,
            claimTransfers: claimLogData.claimTransfers[claimId],
            bullaTag: claimLogData.bullaTags[claimId],
            logs: claimLogData.claimEvents[claimId] ?? [],
            owners: allOwners,
            financingState,
        });
    };

    const userClaims = await Promise.all(getClaimCreatedEvents(claimEvents).map(getBullaClaimInfo));
    const instantPayments = await getInstantPaymentInfo(resolveTokenInfo, queryAddress, chainId, instantPaymentEvents);
    const bullaItems = [...userClaims, ...instantPayments].sort((a, b) => b.created.getTime() - a.created.getTime());

    const userBatchesByTxHash = bullaItems.reduce<Record<string, BullaItemInfo[]>>(
        (acc, claim) => ({ ...acc, [claim.txHash]: [...(acc[claim.txHash] ?? []), claim] }),
        {},
    );

    const financeAcceptedClaimOriginatingClaimIds = Object.values(claimLogData.financingAccepted)
        .filter(
            (x): x is { terms: FinancingTerms; origination: VendorFinancingOrigination } =>
                x !== undefined && x.origination.kind == 'vendor-financing',
        )
        .map(x => x.origination.originatingClaimId);

    const frendLends: FrendLendInfo[] = Object.values(
        await loanEvents.reduce<Promise<Record<string, FrendLendInfo>>>(async (_acc, event) => {
            const acc = await _acc;
            let frendLendInfo: FrendLendInfo;
            switch (event.__typename) {
                case 'LoanOfferedEvent':
                    frendLendInfo = {
                        __type: 'FrendLend',
                        status: 'Offered',
                        loanId: event.loanId,
                        chainId,
                        creditor: event.creditor,
                        debtor: event.debtor,
                        loanAmount: event.loanAmount,
                        tokenInfo: await resolveTokenInfo(chainId, event.token),
                        description: event.description,
                        interestBPS: event.interestBPS,
                        ipfsHash: event.ipfsHash,
                        logs: [event],
                        offerDate: event.blocktime,
                        offerTxHash: event.txHash,
                        termLength: daysToTermLength(secondsToDays(event.termLength)),
                    };
                    break;
                case 'LoanOfferRejectedEvent':
                    frendLendInfo = {
                        ...acc[event.loanId],
                        status: 'Rejected',
                        logs: [...acc[event.loanId].logs, event],
                    };
                    break;
                case 'LoanOfferAcceptedEvent':
                    frendLendInfo = {
                        ...acc[event.loanId],
                        status: 'Accepted',
                        logs: [...acc[event.loanId].logs, event],
                    };
                    break;
            }
            return Promise.resolve({
                ...acc,
                [event.loanId]: frendLendInfo,
            });
        }, Promise.resolve({})),
    );

    const bullaTxHashBitmap = bullaItemEvents.reduce<TxHashBitmap>((acc, tx) => ({ ...acc, [tx.txHash.toLowerCase()]: true }), {});

    const { poolDeposits, poolRedemptions } = await getPoolEventInfo(poolEvents, resolveTokenInfo, chainId);
    const { swapEventInfo } = await getSwapEventInfo(swapEvents, resolveTokenInfo, chainId);
    return {
        bullaItemEvents: eventsASC,
        userClaims,
        instantPayments,
        poolDeposits,
        poolRedemptions,
        swaps: swapEventInfo,
        userBatchesByTxHash,
        financeAcceptedClaimOriginatingClaimIds,
        frendLends,
        bullaTxHashBitmap,
        bullaItemMetadataById: {},
        fetched: false,
        importFetched: false,
    };
};

export function categorizeBullaItemEvents(eventsASC: BullaItemEvent[]): {
    claimEvents: ClaimEvent[];
    instantPaymentEvents: InstantPayContractEvent[];
    loanEvents: FrendLendEvent[];
    factoringEvents: FactoringEvent[];
    poolEvents: PoolEvent[];
    swapEvents: BullaSwapEvent[];
} {
    return eventsASC.reduce<{
        instantPaymentEvents: InstantPayContractEvent[];
        claimEvents: ClaimEvent[];
        loanEvents: FrendLendEvent[];
        factoringEvents: FactoringEvent[];
        poolEvents: PoolEvent[];
        swapEvents: BullaSwapEvent[];
    }>(
        (aggregator, event) => {
            if (isInstantPaymentEvent(event)) {
                return {
                    ...aggregator,
                    instantPaymentEvents: [...aggregator.instantPaymentEvents, event],
                };
            } else if (isFrendLendEvent(event)) {
                return {
                    ...aggregator,
                    loanEvents: [...aggregator.loanEvents, event],
                    claimEvents:
                        event.__typename !== 'LoanOfferAcceptedEvent' ? aggregator.claimEvents : [...aggregator.claimEvents, event],
                };
            } else if (isFactoringEvent(event) || isUnFactoredEvent(event) || isInvoiceReconciledEvent(event)) {
                return {
                    ...aggregator,
                    factoringEvents: [...aggregator.factoringEvents, event],
                };
            } else if (isPoolEvent(event)) {
                return {
                    ...aggregator,
                    poolEvents: [...aggregator.poolEvents, event],
                };
            } else if (isBullaSwapEvent(event)) {
                return {
                    ...aggregator,
                    swapEvents: [...aggregator.swapEvents, event],
                };
            } else {
                return {
                    ...aggregator,
                    claimEvents: [...aggregator.claimEvents, event],
                };
            }
        },
        { instantPaymentEvents: [], claimEvents: [], loanEvents: [], factoringEvents: [], poolEvents: [], swapEvents: [] },
    );
}

export function getLastPaymentDateFromLogs(logs: ClaimEvent[]): Date {
    return (
        logs
            .filter(
                eventLog =>
                    eventLog.__typename === 'ClaimPaymentEvent' ||
                    eventLog.__typename === 'DrawdownOnReceivableEvent' ||
                    eventLog.__typename === 'ExtraFundsDispersedEvent' ||
                    eventLog.__typename === 'PaymentMadeEvent' ||
                    eventLog.__typename === 'InvoiceFundedEvent' ||
                    eventLog.__typename === 'InvoiceKickbackAmountSentEvent' ||
                    eventLog.__typename === 'InvoiceUnfactoredEvent' ||
                    eventLog.__typename === 'InvoiceImpairedEvent',
            )
            .map(x => x.blocktime ?? new Date(0))
            .sort()[0] ?? new Date(0)
    );
}

function handleFinancingOfferedEvent(logData: ClaimLogData, log: FinancingOfferedEvent): ClaimLogData {
    const claimId = log.tokenId;
    const [claimAmount] = logData.claimEvents[claimId]
        .filter((x): x is ClaimCreatedEvent => x.__typename == 'ClaimCreatedEvent')
        .map(x => x.claimAmount);

    if (claimAmount === undefined) return logData;

    const termLengthDays = secondsToDays(log.termLength);
    const termLength = daysToTermLength(termLengthDays);
    const financingTerms = getFinancingTerms(claimAmount, log.minDownPaymentBPS, log.interestBPS, termLength);

    return { ...logData, financingOffers: { ...logData.financingOffers, [claimId]: financingTerms } };
}

function handleFinancingAcceptedEvent(logData: ClaimLogData, log: FinancingAcceptedEvent): ClaimLogData {
    const claimId = log.tokenId;
    const originatingClaimId = log.originatingClaimId;

    return {
        ...logData,
        financingAccepted: {
            ...logData.financingAccepted,
            [claimId]: {
                terms: logData.financingOffers[originatingClaimId]!,
                origination: { kind: 'vendor-financing', originatingClaimId },
            },
        },
        bullaTags: {
            ...logData.bullaTags,
            [claimId]: logData.bullaTags[originatingClaimId],
        },
    };
}

function handleLoanOfferAcceptedEvent(
    logData: ClaimLogData,
    log: LoanOfferAcceptedEvent,
    loanOffers: Record<string, LoanOfferedEvent>,
): ClaimLogData {
    const claimId = log.tokenId;

    const loanOffer = loanOffers[log.loanId];
    const termLengthDays = secondsToDays(loanOffer.termLength);
    const termLength = daysToTermLength(termLengthDays);
    const financingTerms = getFinancingTerms(loanOffer.loanAmount, 0, loanOffer.interestBPS, termLength);

    return {
        ...logData,
        financingAccepted: {
            ...logData.financingAccepted,
            [claimId]: {
                terms: financingTerms,
                origination: { kind: 'frendlend', originatingLoanId: log.loanId },
            },
        },
    };
}
