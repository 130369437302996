import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { getAddress } from '@ethersproject/address';
import { Field, FieldInputProps, FieldProps, Form, Formik, FormikErrors, FormikTouched } from 'formik';
import React, { useState } from 'react';
import { Contact, isContactsReady, requireContactsReady, useContactValidator, useExtendedContacts } from '../../hooks/useExtendedContacts';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useUIState } from '../../state/ui-state';
import { apply } from '../../tools/common';
import { OrangeButton } from '../inputs/buttons';
import { CloseModalButton } from './common';
import { AccountTagField, disabledInputProps, EmailField } from './create-claim-modal/create-claim-inputs';

type BaseInputProps = {
    errors: FormikErrors<Contact>;
    touched: FormikTouched<Contact>;
    field: FieldInputProps<any>;
    transactionPending: boolean;
};

export type CreateContactModalProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    editState?: Contact;
    defaults?: Partial<Contact>;
};

export const ContactNameField = ({ errors, touched, field, transactionPending }: BaseInputProps) => (
    <FormControl isRequired isInvalid={!!errors.name && !!touched.name}>
        <FormLabel htmlFor="name">Contact Name</FormLabel>
        <InputGroup>
            <Input placeholder="Enter name" isDisabled={transactionPending} data-testid="contactName" {...disabledInputProps} {...field} />
        </InputGroup>
        {touched.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
    </FormControl>
);

export const EthAddressField = ({ errors, touched, field, transactionPending }: BaseInputProps) => (
    <FormControl isRequired isInvalid={!!errors.walletAddress && !!touched.walletAddress}>
        <FormLabel htmlFor="walletAddress">Wallet Address</FormLabel>
        <InputGroup>
            <Input
                placeholder="Enter address"
                isDisabled={transactionPending}
                data-testid="ethAddress"
                {...disabledInputProps}
                {...field}
            />
        </InputGroup>
        {touched.walletAddress && <FormErrorMessage>{errors.walletAddress}</FormErrorMessage>}
    </FormControl>
);

export const CreateContactModal = ({ triggerElement, editState, defaults }: CreateContactModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const contactsContext = useExtendedContacts();
    const { transactionPending } = useUIState();
    const { schema: _schema, editSchema } = useContactValidator();
    const initialValues: Contact = {
        name: editState?.name ?? defaults?.name ?? '',
        walletAddress: editState?.walletAddress ?? defaults?.walletAddress ?? '',
        emailAddress: editState?.emailAddress ?? defaults?.emailAddress ?? '',
        groups: editState?.groups ?? defaults?.groups ?? [],
    };
    const [inProgress, setInProgress] = useState(false);
    const schema = editState ? editSchema(editState) : _schema;
    const isMobile = useIsMobile();
    const dropdownModalRef = React.useRef<HTMLDivElement>(null);

    const contacts = isContactsReady(contactsContext) ? contactsContext.contacts : [];
    const contactGroups = contacts.map(contact => contact.groups).flat();

    return (
        <>
            {triggerElement(onOpen)}
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}
                motionPreset="slideInBottom"
                size={isMobile ? 'full' : '3xl'}
                closeOnEsc
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <Formik
                    validateOnBlur
                    onSubmit={contact => {
                        if (!isContactsReady(contactsContext)) requireContactsReady();

                        setInProgress(true);
                        const newContact: Contact = { ...contact, walletAddress: getAddress(contact.walletAddress) };

                        return (
                            editState
                                ? contactsContext.editContactAsync(editState, newContact)
                                : contactsContext.addContactAsync(newContact)
                        ).then(_ => {
                            setInProgress(false);
                            onClose();
                        });
                    }}
                    validationSchema={schema}
                    initialValues={initialValues}
                >
                    {({ errors, touched, setFieldValue, isValid, dirty, setStatus }) => (
                        <Form placeholder={''}>
                            <ModalContent py="4" px="2" maxH="80%" maxW="xl" ref={dropdownModalRef}>
                                <CloseModalButton onClose={onClose} />
                                <ModalBody pb={6} pt={4}>
                                    <Text color="icon_dark" textStyle="labelLg">
                                        {editState ? 'Edit Contact' : 'New Contact'}
                                    </Text>
                                    <Box h="8" />
                                    <Stack spacing="2" w={isMobile ? '100%' : '60%'}>
                                        <Field name="name">
                                            {({ field }: FieldProps) => (
                                                <ContactNameField {...{ field, transactionPending, errors, touched, setFieldValue }} />
                                            )}
                                        </Field>
                                        <Field name="walletAddress">
                                            {({ field }: FieldProps) => (
                                                <EthAddressField {...{ field, transactionPending, errors, touched, setFieldValue }} />
                                            )}
                                        </Field>
                                        <Field name="emailAddress">
                                            {({ field }: FieldProps) => (
                                                <EmailField
                                                    {...{
                                                        field,
                                                        isDisabled: transactionPending,
                                                        error: errors.emailAddress,
                                                        touched: touched.emailAddress,
                                                        label: 'Email Address',
                                                        required: false,
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <Field name="groups">
                                            {({ field }: FieldProps) => (
                                                <AccountTagField
                                                    {...{
                                                        field,
                                                        isDisabled: transactionPending,
                                                        error: errors.groups,
                                                        touched: touched.groups,
                                                        setTags: apply(setFieldValue, field.name),
                                                        setStatus,
                                                        label: 'Groups',
                                                        creatingExpense: false,
                                                        placeholder: 'Find or create new group',
                                                        dropdownModalRef,
                                                        contactGroups,
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Stack>
                                </ModalBody>
                                <ModalFooter>
                                    <Spacer />
                                    <OrangeButton
                                        type="submit"
                                        isLoading={inProgress}
                                        isDisabled={!dirty || !isValid}
                                        data-testid="triggerCreateContact"
                                        h={isMobile ? '30px' : '40px'}
                                    >
                                        {editState ? 'Save' : 'Add'}
                                    </OrangeButton>
                                </ModalFooter>
                            </ModalContent>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};
