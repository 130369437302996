import {
    Box,
    Divider,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { CloseModalButton } from '../../../components/modals/common';
import { OrangeButton, WhiteButton } from '../../../components/inputs/buttons';
import {
    AttachmentField,
    ChainSelector,
    ClaimAmountField,
    LinkTextField,
    TaxSelector,
    TaxType,
} from '../../../components/modals/create-claim-modal/create-claim-inputs';
import * as Yup from 'yup';
import { TokenInfoByChainIdAndAddress, useTokenRepo } from '../../../hooks/useTokenRepo';
import { useWeb3 } from '../../../hooks/useWeb3';
import { ChainId, SUPPORTED_NETWORKS } from '../../../data-lib/networks';
import { apply, getBase64 } from '../../../tools/common';
import { BullaFileObject, BullaItemAttachment } from '../../../components/modals/create-claim-modal/create-claim-modal';
import { generateCreateClaimLink } from './my-links-page';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useLinksApi } from '../../../hooks/useLinksApi';
import { Tax } from '../../../components/display/views/new-invoice';

type MyLinkModalProps = {
    isOpen: boolean;
    onClose: VoidFunction;
    onSuccess: VoidFunction;
    linkToEdit?: string;
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').optional(),
    token: Yup.object().required('Token is required'),
});

const parseLinkForInitialValues = (link: string, getTokenByChainIdAndAddress: TokenInfoByChainIdAndAddress) => {
    if (!link) return null;

    const urlObj = new URL(link);
    const params = new URLSearchParams(
        urlObj.hash.includes('link-pay') ? urlObj.hash.replace('#/link-pay/', '').replace('#/link-pay', '') : urlObj.hash.substring(2),
    );

    const tokenAddress = params.get('token') as string;
    const chainId = Number(params.get('network')) as ChainId;
    const taxRate = params.get('taxRate');
    const taxType = params.get('taxType');

    const token = getTokenByChainIdAndAddress(chainId as ChainId)(tokenAddress)?.token;

    const tax =
        taxRate && taxType
            ? ({
                  taxRate: taxRate.toString(),
                  taxType: taxType as TaxType,
              } as Tax)
            : undefined;

    return {
        linkName: params.get('name') || '',
        yourName: params.get('recipient') || '',
        description: params.get('description') || '',
        chainId,
        amount: params.get('amount') || '',
        token: token,
        tax,
    };
};

export const MyLinkModal = ({ isOpen, onClose, onSuccess, linkToEdit }: MyLinkModalProps) => {
    const { connectedNetwork, connectedNetworkConfig } = useWeb3();
    const [isLoading, setIsLoading] = useState(false);
    const actingWallet = useActingWalletAddress();
    const { resolveTokenInfo } = useTokenRepo();
    const { saveLink } = useLinksApi();
    const [isTaxSelected, setIsTaxSelected] = useState(false);
    const { getTokenByChainIdAndAddress } = useTokenRepo();

    const parsedInitialValues = linkToEdit ? parseLinkForInitialValues(linkToEdit, getTokenByChainIdAndAddress) : null;
    const initialToken = parsedInitialValues?.token || connectedNetworkConfig.nativeCurrency.tokenInfo.token;

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            closeOnOverlayClick={true}
            closeOnEsc={true}
            size="2xl"
        >
            <ModalOverlay />
            <ModalContent>
                <Formik
                    initialValues={{
                        linkName: parsedInitialValues?.linkName || '',
                        yourName: parsedInitialValues?.yourName || '',
                        description: parsedInitialValues?.description || '',
                        chainId: parsedInitialValues?.chainId || connectedNetwork,
                        amount: parsedInitialValues?.amount || '',
                        token: initialToken,
                        attachment: undefined as BullaFileObject | undefined,
                        tax: parsedInitialValues?.tax || undefined,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async values => {
                        setIsLoading(true);

                        const tokenInfo = await resolveTokenInfo(values.chainId, values.token.address);

                        const claimLink = generateCreateClaimLink(
                            'Payment',
                            actingWallet,
                            values.chainId,
                            values.yourName,
                            tokenInfo,
                            values.amount,
                            undefined,
                            values.description,
                            Date.now(),
                            values.linkName,
                            values.tax,
                        );

                        const attachment =
                            values.attachment && values.attachment.file !== 'not-uploaded'
                                ? await getBase64(values.attachment.file)
                                : undefined;

                        const success = await saveLink(claimLink, attachment);

                        if (success) {
                            setIsLoading(false);
                            onSuccess();
                            onClose();
                        }
                    }}
                >
                    {({ errors, touched, setFieldValue, values }) => {
                        return (
                            <Form placeholder={''}>
                                <ModalHeader display="flex" flexDirection="row">
                                    <VStack align="flex-start" spacing={0}>
                                        <Text color="heading" fontWeight={'700'} fontSize="20px">
                                            Create new pay me link
                                        </Text>
                                        <Text fontSize={'15px'} fontWeight={'400'} color="gray.600">
                                            Create a shareable link for anyone to send you payment in over 20+ cryptocurrencies.
                                        </Text>
                                    </VStack>
                                </ModalHeader>

                                <Box>
                                    <Divider width="100%" sx={{ height: '0.5px' }} />
                                </Box>

                                <ModalBody>
                                    <VStack spacing="4">
                                        <Field name="linkName">
                                            {({ field }: FieldProps) => (
                                                <LinkTextField
                                                    {...{
                                                        field,
                                                        setFieldValue,
                                                        error: errors.linkName,
                                                        touched: touched.linkName,
                                                        label: 'Link name',
                                                        isDisabled: isLoading,
                                                        placeholder: 'How should we name this link?',
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <Field name="yourName">
                                            {({ field }: FieldProps) => (
                                                <LinkTextField
                                                    {...{
                                                        field,
                                                        setFieldValue,
                                                        error: errors.yourName,
                                                        touched: touched.yourName,
                                                        label: 'Your name',
                                                        isDisabled: isLoading,
                                                        placeholder: 'This will show up in user’s payment dialog',
                                                    }}
                                                />
                                            )}
                                        </Field>

                                        <Field name="chainId">
                                            {({ field }: FieldProps) => (
                                                <Box alignSelf="flex-start">
                                                    <Text mb={2} fontWeight="500">
                                                        Network
                                                    </Text>
                                                    <ChainSelector
                                                        chainId={field.value}
                                                        selectableChains={SUPPORTED_NETWORKS}
                                                        onChainSelected={chainId => setFieldValue('chainId', chainId)}
                                                        width="100%"
                                                    />
                                                </Box>
                                            )}
                                        </Field>

                                        <Field name="amount">
                                            {({ field }: FieldProps) => (
                                                <ClaimAmountField
                                                    {...{
                                                        claimType: 'Invoice',
                                                        networkOverride: connectedNetwork,
                                                        field,
                                                        isDisabled: false,
                                                        includeNativeToken: true,
                                                        error: errors.amount,
                                                        touched: touched.amount,
                                                        setAmount: apply(setFieldValue, 'amount'),
                                                        setToken: apply(setFieldValue, 'token'),
                                                        amount: values.amount,
                                                        token: values.token,
                                                        label: 'Token',
                                                        disableBalanceLabels: true,
                                                    }}
                                                />
                                            )}
                                        </Field>

                                        <Box alignSelf="flex-start">
                                            <TaxSelector
                                                isTaxSelected={isTaxSelected}
                                                setIsTaxSelected={setIsTaxSelected}
                                                tax={values.tax}
                                                setTax={apply(setFieldValue, 'tax')}
                                            />
                                        </Box>

                                        <Field name="description">
                                            {({ field }: FieldProps) => (
                                                <LinkTextField
                                                    {...{
                                                        field,
                                                        setFieldValue,
                                                        error: errors.description,
                                                        touched: touched.description,
                                                        label: 'Shared description',
                                                        isDisabled: isLoading,
                                                        required: false,
                                                        placeholder: 'This description will show up in user’s payment dialog',
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </VStack>
                                </ModalBody>

                                <Box mt={4}>
                                    <Divider width="100%" sx={{ height: '0.5px' }} />
                                </Box>

                                <ModalFooter>
                                    <HStack w="100%" spacing={4}>
                                        <WhiteButton onClick={onClose} h="12" w="50%">
                                            Cancel
                                        </WhiteButton>
                                        <OrangeButton type="submit" w="50%" isLoading={isLoading}>
                                            {linkToEdit ? 'Update' : 'Create'}
                                        </OrangeButton>
                                    </HStack>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>

                <CloseModalButton onClose={onClose} />
            </ModalContent>
        </Modal>
    );
};
