import {
    Button,
    Checkbox,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ExportFileFormat, handleExport, toReportDateTimeString } from '../../../tools/excelExport';
import { CloseModalButton } from '../common';
import { ExportFileFormatSelector } from '../ledger-export-wizard-modal/ledger-export-modal';
import { DomainTaxLot, DomainSalesLot, DomainOutGrouping, DomainTransfer } from './8949-success-card';
import { weiToDisplayAmt } from '../../../data-lib/ethereum';
import { NETWORKS } from '../../../data-lib/networks';
import { toDateDisplay, toUSD } from '../../../tools/common';
import { BigNumber } from 'ethers';

export type _8949ExportModalProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    filteredIns: DomainTaxLot[];
    filteredOuts: DomainSalesLot[];
    filteredGroupings: DomainOutGrouping[];
    transfers: DomainTransfer[];
};

type SelectedExportTypes = {
    matching: boolean;
    transactionsIn: boolean;
    transactionsOut: boolean;
    transfers: boolean;
};

type ExportType = keyof SelectedExportTypes;

const toDisplayText = (exportType: ExportType) => {
    switch (exportType) {
        case 'matching':
            return 'Transactions Matching';
        case 'transactionsIn':
            return 'Transactions (In)';
        case 'transactionsOut':
            return 'Transactions (Out)';
        case 'transfers':
            return 'Transfers';
    }
};

const transactionMatchingToExportRow = (entry: DomainOutGrouping) => {
    return entry.ins.map(inTransfer => [
        entry.transfer.token.token.symbol,
        weiToDisplayAmt({ amountWei: inTransfer.usedAmount, decimals: entry.transfer.token.token.decimals }),
        entry.transfer.from,
        entry.transfer.to,
        toReportDateTimeString(inTransfer.transfer.timestamp),
        toReportDateTimeString(entry.transfer.timestamp),
        inTransfer.proceeds?.toString() ?? 'N/A',
        inTransfer.cost?.toString() ?? 'N/A',
        inTransfer.gainLoss?.toString() ?? 'N/A',
        entry.transfer.lotNumber,
        inTransfer.transfer.lotNumber,
        entry.transfer.usdMark?.toString() ?? 'N/A',
        inTransfer.transfer.usdValue?.toString() ?? 'N/A',
        weiToDisplayAmt({ amountWei: inTransfer.afterInBalance, decimals: entry.transfer.token.token.decimals }),
        entry.transfer.usdValue?.toString() ?? 'N/A',
        weiToDisplayAmt({ amountWei: inTransfer.afterOutBalance, decimals: entry.transfer.token.token.decimals }),
        entry.transfer.txHash,
        inTransfer.transfer.txHash,
        entry.transfer.from,
        NETWORKS[entry.transfer.chainId].label,
    ]);
};

const transactionToExportRow = (entry: DomainTaxLot | DomainSalesLot | DomainTransfer) => {
    return [
        toReportDateTimeString(entry.timestamp),
        NETWORKS[entry.chainId].label,
        entry.from,
        entry.to,
        entry.token.token.symbol,
        toUSD(entry.displayAmount, true).replace('$', '').replace(',', ''),
        entry.usdValue?.toString() ?? 'N/A',
        entry.usdMark?.toString() ?? 'N/A',
        entry.description,
        entry.txHash,
    ];
};

const transactionInToExportRow = (entry: DomainTaxLot) => transactionToExportRow(entry);
const transactionOutToExportRow = (entry: DomainSalesLot) => transactionToExportRow(entry);
const transferToExportRow = (entry: DomainTransfer) => transactionToExportRow(entry);

const TRANSACTION_MATCHING_EXPORT_HEADERS = [
    'token',
    'amount',
    'from',
    'to',
    'date acquired',
    'date sold',
    'proceeds sale',
    'cost',
    'gain/loss',
    'sales lot',
    'tax lot',
    'sold at',
    'tax lot total',
    'tax balance',
    'sales lot total',
    'sales balance',
    'sales lot txn',
    'tax lot txn',
    'transacting wallet',
    'chain',
];

const TRANSACTION_IN_EXPORT_HEADERS = [
    'date received',
    'chain',
    'from',
    'to',
    'token',
    'amount',
    'usd value',
    'usd mark',
    'description',
    'transaction hash',
];

const TRANSACTION_OUT_EXPORT_HEADERS = [
    'date sent',
    'chain',
    'from',
    'to',
    'token',
    'amount',
    'usd value',
    'usd mark',
    'description',
    'transaction hash',
];

const TRANSFER_EXPORT_HEADERS = [
    'date transfered',
    'chain',
    'from',
    'to',
    'token',
    'amount',
    'usd value',
    'usd mark',
    'description',
    'transaction hash',
];

export const _8949ExportModal = ({ triggerElement, filteredIns, filteredOuts, filteredGroupings, transfers }: _8949ExportModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [format, setFormat] = React.useState<ExportFileFormat>('csv');

    const [selectedExportTypes, setSelectedExportTypes] = React.useState<SelectedExportTypes>({
        matching: false,
        transactionsIn: false,
        transactionsOut: false,
        transfers: false,
    });

    const onClick = async () => {
        setInProgress(true);
        const promises = [];

        if (selectedExportTypes.matching) {
            const matchingRows = filteredGroupings
                .sort((a, b) => b.transfer.timestamp.getTime() - a.transfer.timestamp.getTime())
                .flatMap(entry => transactionMatchingToExportRow(entry));

            promises.push(handleExport(format, 'transactions-matching', TRANSACTION_MATCHING_EXPORT_HEADERS, matchingRows));
        }

        if (selectedExportTypes.transactionsIn) {
            const inRows = filteredIns
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
                .map(entry => transactionInToExportRow(entry));

            promises.push(handleExport(format, 'transactions-in', TRANSACTION_IN_EXPORT_HEADERS, inRows));
        }

        if (selectedExportTypes.transactionsOut) {
            const outRows = filteredOuts
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
                .map(entry => transactionOutToExportRow(entry));

            promises.push(handleExport(format, 'transactions-out', TRANSACTION_OUT_EXPORT_HEADERS, outRows));
        }

        if (selectedExportTypes.transfers) {
            const outRows = transfers
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
                .map(entry => transferToExportRow(entry));

            promises.push(handleExport(format, 'transfers', TRANSFER_EXPORT_HEADERS, outRows));
        }

        await Promise.all(promises);
        setInProgress(false);
        onClose();
    };

    return (
        <>
            {triggerElement(onOpen)}
            <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" size="md" closeOnEsc scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent py="4" px="2" maxH="80%">
                    <CloseModalButton onClose={onClose} />
                    <ModalHeader pb={6} pt={6}>
                        <Text color="icon_dark" textStyle="labelLg">
                            Export
                        </Text>
                    </ModalHeader>
                    <ModalBody>
                        <Stack>
                            {(Object.keys(selectedExportTypes) as (keyof SelectedExportTypes)[]).map(exportType => (
                                <HStack key={exportType}>
                                    <Checkbox
                                        isChecked={selectedExportTypes[exportType]}
                                        onChange={_ => setSelectedExportTypes(prev => ({ ...prev, [exportType]: !prev[exportType] }))}
                                    />
                                    <Text fontWeight={'bold'}>{toDisplayText(exportType)}</Text>
                                </HStack>
                            ))}
                            <HStack key={'format'} mt="4">
                                <ExportFileFormatSelector selectedFormat={format} onFormatSelected={setFormat} />
                                <Spacer />
                            </HStack>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="white" color="dark" border="1px" borderColor="dark" px="8" py="6" onClick={onClose}>
                            Cancel
                        </Button>
                        <Spacer />
                        <Button px="8" py="6" fontWeight="500" colorScheme="accent" type="submit" isLoading={inProgress} onClick={onClick}>
                            Export
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
