import { Box, BoxProps, ButtonProps, Tag } from '@chakra-ui/react';
import React from 'react';
import { ClaimStatus, FrendLendOffer } from '../../data-lib/data-model';
import { ChakraCompose } from '../../tools/types';
import { Direction } from '../display/claim-table';

export const NewTag = () => (
    <Tag bg="brand.bulla_yellow" color="white" size="sm">
        New
    </Tag>
);

export const Badge = ({ children, ...overrides }: ChakraCompose & BoxProps & ButtonProps) => (
    <Box color="scheme.light" as={overrides?.as} textStyle="badge" onClick={overrides?.onClick} {...overrides}>
        {children}
    </Box>
);

export const BaseBadge = ({ children, ...overrides }: ChakraCompose & BoxProps & ButtonProps) => (
    <Badge fontWeight={500} fontSize="14px" w="fit-content" {...overrides}>
        {children}
    </Badge>
);

export const getStatusBadge = (
    claimStatus: ClaimStatus | FrendLendOffer['status'],
    direction: Direction,
    paidPaymentDate?: string,
): React.ReactNode => {
    const badges = {
        Pending: (
            <BaseBadge bg={direction == 'Out' ? 'warning' : 'badgeColors.awaiting'}>
                {direction == 'Out' ? 'Payment Due' : 'Awaiting Payment'}
            </BaseBadge>
        ),
        Paid: <BaseBadge bg="badgeColors.paid">Paid {paidPaymentDate && ` on ${paidPaymentDate}`}</BaseBadge>,
        Rejected: <BaseBadge bg="badgeColors.rejected">Rejected</BaseBadge>,
        Rescinded: (
            <BaseBadge bg="badgeColors.rescinded" color="scheme.dark">
                Rescinded
            </BaseBadge>
        ),
        Repaying: (
            <BaseBadge bg="badgeColors.rescinded" color="scheme.dark">
                Repaying
            </BaseBadge>
        ),
        Accepted: <BaseBadge bg="badgeColors.paid">Accepted</BaseBadge>,
        Offered: <BaseBadge bg="brand.200">Offered</BaseBadge>,
        Factored: <BaseBadge bg="brand.200">Factored</BaseBadge>,
        Unfactored: <BaseBadge bg="brand.200">Unfactored</BaseBadge>,
        Impaired: <BaseBadge bg="brand.200">Impaired</BaseBadge>,
    };
    return badges[claimStatus];
};

export const getStatusString = (
    claimStatus: ClaimStatus | FrendLendOffer['status'],
    direction: Direction,
    paidPaymentDate?: string,
): string => {
    const statusStrings = {
        Pending: direction == 'Out' ? 'Payment Due' : 'Awaiting Payment',
        Paid: `Paid ${paidPaymentDate ? `on ${paidPaymentDate}` : ''}`,
        Rejected: 'Rejected',
        Rescinded: 'Rescinded',
        Repaying: 'Repaying',
        Accepted: 'Accepted',
        Offered: 'Offered',
        Factored: 'Factored',
        Unfactored: 'Unfactored',
        Impaired: 'Impaired',
    };
    return statusStrings[claimStatus];
};

export const parseStatusToSoftledgerCode = (statusString: string): number => {
    if (statusString.startsWith('Paid on')) {
        return 1;
    }
    switch (statusString) {
        case 'Rescinded':
            return 0;
        case 'Awaiting Payment':
        case 'Payment Due':
            return 2;
        case 'Rejected':
            return 3;
        case 'Factored':
            return 4;
        default:
            return 5;
    }
};

export function getSoftledgerCodesByStatus(softledgerStatus: number) {
    if (softledgerStatus === 2) {
        return ['110100', '410000', '', ''];
    } else if (softledgerStatus === 1) {
        return ['110100', '410000', '185000', '110100'];
    } else {
        return ['', '', '', ''];
    }
}

export const StatusBadge = ({
    claimStatus,
    direction,
    ...props
}: {
    claimStatus: ClaimStatus;
    direction: Direction;
} & ChakraCompose) => {
    return <React.Fragment {...props}>{getStatusBadge(claimStatus, direction)}</React.Fragment>;
};
